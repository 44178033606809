// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10565(46975b82e5)-C18/09/2024-06:49:32-B18/09/2024-07:20:02' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10565(46975b82e5)-C18/09/2024-06:49:32-B18/09/2024-07:20:02",
  branch: "master",
  latestTag: "6.1",
  revCount: "10565",
  shortHash: "46975b82e5",
  longHash: "46975b82e557e37552db5070d7d75bafc60d4c6d",
  dateCommit: "18/09/2024-06:49:32",
  dateBuild: "18/09/2024-07:20:02",
  buildType: "Ansible",
  } ;
